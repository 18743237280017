/*
 * NOTE: BOOMWSXIV-907 : core-js/es/array/iterator
 * https://github.com/babel/babel/issues/9872
 * https://github.com/jorenvanhee/dynamic-imports-with-preset-env-test/commit/041464c2d48f68545daa75bab6d626da2c041a9b
 */
import 'core-js/es/array/iterator';

import 'libs/Modernizr.js';
import { ShowsCarouselLazy } from './modules/ShowsCarouselLazy.js';
import { SliderCarouselLazy } from './modules/SliderCarouselLazy';
import { AppScreenshotsCarousel } from './modules/AppScreenshotsCarousel';
import { CarouselWidget } from './modules/CarouselWidget.js';
import { Footer } from './modules/Footer.js';
import { CustomActions } from './modules/CustomActions.js';
import { headerAnimation } from 'modules/HeaderAnimation';
// import LikeButton from './modules/LikeButton.js';
import TrackViews from './modules/TrackViews.js';
import FeedBlock from './modules/FeedBlock';
// import { setContentLikeViewVisibility } from 'modules/viewsAndLikesActions';
import { getLogOptions } from 'constants/logging';
import Emojis from 'modules/emojis/Emojis';

const App = function () {
  this.pageId = null;
  this.device = null;
  this.ready = false;
  this.mediaInitialised = false;
  this.log = getLogOptions({ id: 'app' });
  this.carouselState = { shows: false, slider: false, app: false, small: false };
  return this;
};

App.prototype = {
  init: function () {
    if (this.canUseWebP()) {
      $('html').addClass('webp');
    }

    const carouseltype = 'lazy';
    const carouselSelector = $('.shows_carousel .swiper-wrapper');

    // LOGO PUSH & GOOGLY EYES :: LOGO PUSH hand sprite : removed - see BOOMWSXIV-1388
    // Only trigger the animation on a landing page
    if ($('body').hasClass('is-landing-page') && carouselSelector.find('[data-gif-image]').length > 0) {
      headerAnimation.init($('#logo_push'), carouselSelector, carouseltype);
    }

    // Trigger showmore functionality
    // TODO: Combine the showmore functionality together
    if ($('body').hasClass('characters')) {
      if (document.getElementById('character-description') && document.getElementById('character__desc__container')) {
        const defaultCharacterDescHeight = document.getElementById('character-description').offsetHeight;
        const characterDesc = document.getElementById('character-description');
        const showMoreButton = document.getElementById('character-show-more');

        if (document.getElementById('character__desc__container').offsetHeight > defaultCharacterDescHeight) {
          const moreText = showMoreButton.getElementsByClassName('show-more')[0];
          const lessText = showMoreButton.getElementsByClassName('show-less')[0];

          showMoreButton.addEventListener('click', () => {
            if (!characterDesc.classList.contains('active')) {
              moreText.classList.remove('active');
              lessText.classList.add('active');
              characterDesc.classList.add('active');
              characterDesc.style.height = `${document.getElementById('character__desc__container').offsetHeight}px`;
            } else {
              lessText.classList.remove('active');
              moreText.classList.add('active');
              characterDesc.classList.remove('active');
              characterDesc.style.height = `${defaultCharacterDescHeight}px`;
            }
          });
        } else {
          showMoreButton.classList.add('hidden');
        }
      }
    }

    $('.nav_menu_button').on('click', function () {
      $(this).toggleClass('active');
      $('.mobile_nav.mobileonly').toggleClass('active'); // STE NOTE:: This was different in CARTOONITO
      // $('nav').toggleClass('active');  // STE NOTE:: This is in BOOM
    });

    $('.close_mobile_menu').on('click', function () {
      $('.nav_menu_button').removeClass('active');
      $('.mobile_nav.mobileonly').removeClass('active'); // STE NOTE:: This was different in CARTOONITO
      // $('nav').removeClass('active');  // STE NOTE:: This is in BOOM
    });

    $('.animals-nav__mobilemenubutton').on('click', function () {
      $('.animals-nav').toggleClass('animals-nav--active');
    });

    // STE NOTE:: Added from CARTOONITO
    $('.letscreate-nav__mobilemenubutton').on('click', function () {
      $('.letscreate-nav').toggleClass('letscreate-nav--active');
    });

    $(document).on('touchstart', function () {
      $('body').addClass('show_all_carousel_nav_buttons');
    });
    $(document).on('touchend', function () {
      $('body').removeClass('show_all_carousel_nav_buttons');
    });
    // STE NOTE:: End added from CARTOONITO

    $('.buttons.menu ul > li > a').on('click', function (e) {
      if ($(this).parent().hasClass('active')) {
        $('.buttons.menu ul > li').removeClass('active');
      } else {
        $('.buttons.menu ul > li').removeClass('active');
        $('.sort').removeClass('active');
        $(this).parent().addClass('active');
      }
      e.stopPropagation();
    });

    $('.sort > a').on('click', function (e) {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
        $('.buttons.menu ul > li').removeClass('active');
      }
      e.stopPropagation();
    });

    $('html').click(function () {
      $('.buttons.menu ul > li').removeClass('active');
      $('.sort').removeClass('active');
    });

    $('head').attr({ id: window.location.hostname });

    //Setup post messaging for scrolling via iframe content e.g. scrollPageY:150 == scroll page 150px down
    $(window).on('message', function (e) {
      e = e.originalEvent;
      if (typeof e === 'undefined') {
        return;
      }

      if (e.data && e.data.split && e.data.split(':')[0] == 'scrollPageY') {
        const iframetopOffset = $('#gameplay_page_iframe').offset().top;
        const y = parseInt(e.data.split(':')[1]);
        window.scrollTo(0, y + iframetopOffset);
      }
      if (e.data && e.data.split && e.data.split(':')[0] == 'documentHeight') {
        const _msgHeight = parseInt(e.data.split(':')[1]);
        $('#gameplay_page_iframe').css({ height: _msgHeight + 'px' });
      }
    });

    if ($('.view_counter[data-id]').length > 0) {
      new TrackViews({
        postURL: TurnerToons.baseURL + 'track_view',
        $viewCounter: $('.view_counter').first(),
        type: $('.view_counter').first().data('type'),
        id: $('.view_counter').first().data('id'),
      });
    }

    new Footer();

    new CustomActions();
  },

  canUseWebP: function () {
    const elem = document.createElement('canvas');
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!(elem.getContext && elem.getContext('2d'))) {
      const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  },

  convertFileExtensionToWebp: function (url) {
    // console.log('convertFileExtensionToWebp USE_WEBP', USE_WEBP);
    return this.canUseWebP() ? url.replace(new RegExp('.(' + ['jpg', '.jpeg', 'png'].join('|') + ')$', 'i'), '.webp') : url;
  },

  lazyloadElements: function (pageId, device) {
    this.lazyloadPageComponents(pageId, device);
    this.lazyloadImages(pageId, device);
  },

  lazyloadPageComponents: function (pageId, device) {
    this.pageId = pageId;
    this.device = device;
    this.log.enabled && console.log(`${this.log.prep} lazyloadPageComponents | pageId ${this.pageId} | device ${this.device.type}`, this.log.clr);
    /**
     * Lazy loading modules
     */
    switch (pageId) {
      case 'comicplayer':
        if (TurnerToons.COMIC_DATA.comic_base) {
          import(/* webpackChunkName: "comicplayer" */ 'pages/Comics.js')
            .then(({ default: Component }) => {
              new Component({ data: TurnerToons.COMIC_DATA });
            })
            .catch((error) => {
              console.error('An error occurred while loading the comicplayer component : ', error);
            });
        }
        break;
      case 'games':
      case 'game':
      case 'web_game_category':
      case 'gameplay':
        import(/* webpackChunkName: "games" */ 'pages/Games.js')
          .then(({ default: Component }) => {
            // new Component();
          })
          .catch((error) => {
            console.error('An error occurred while loading the game component : ', error);
          });
        break;
      case 'appplay':
        import(/* webpackChunkName: "appplayer" */ 'pages/Apps.js')
          .then(({ default: Component }) => {
            new Component(device);
          })
          .catch((error) => {
            console.error('An error occurred while loading the appplayer component : ', error);
          });
        break;
      case 'editorial':
        import(/* webpackChunkName: "editiorial" */ 'pages/Editorial.js')
          .then(({ default: Component }) => {
            // new Component();
          })
          .catch((error) => {
            console.error('An error occurred while loading the editorial component : ', error);
          });
        break;
      case 'videoplayer':
      case 'videos':
        import(/* webpackChunkName: "videoplayer" */ 'pages/Videos.js')
          .then(({ default: Component }) => {
            new Component(this.log);
          })
          .catch((error) => {
            console.error('An error occurred while loading the video component : ', error);
          });
        break;
      case 'characterplayer':
      case 'characters':
        import(/* webpackChunkName: "characterplayer" */ 'pages/Characters.js')
          .then(({ default: Component }) => {
            // new Component();
          })
          .catch((error) => {
            console.error('An error occurred while loading the characters component : ', error);
          });
        break;
      case 'shows':
        import(/* webpackChunkName: "showplayer" */ 'pages/Shows.js')
          .then(({ default: Component }) => {
            new Component(this.log);
          })
          .catch((error) => {
            console.error('An error occurred while loading the shows component : ', error);
          });
        break;
      case 'letscreate':
      case 'letscreate_gallery':
      case 'letscreate_colouring':
      case 'letscreate_craft':
      case 'letscreate_cooking':
        import(/* webpackChunkName: "letscreate" */ 'pages/LetsCreate.js')
          .then(({ default: Component }) => {
            // new Component();
          })
          .catch((error) => {
            console.error('An error occurred while loading the letscreate component : ', error);
          });
        break;
    }
    this.onReady();
  },

  lazyloadImages: function (pageId, device) {
    // https://imagekit.io/blog/lazy-loading-images-complete-guide/
    let lazyloadImages;

    if ('IntersectionObserver' in window) {
      lazyloadImages = document.querySelectorAll('.lazy');
      const imageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const image = entry.target;
            image.src = image.dataset.src;
            image.classList.remove('lazy');
            imageObserver.unobserve(image);
          }
        });
      });

      lazyloadImages.forEach(function (image) {
        imageObserver.observe(image);
      });
    } else {
      let lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll('.lazy');

      const lazyload = () => {
        if (lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function () {
          const scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function (img) {
            if (img.offsetTop < window.innerHeight + scrollTop) {
              img.src = img.dataset.src;
              img.classList.remove('lazy');
            }
          });
          if (lazyloadImages.length == 0) {
            document.removeEventListener('scroll', lazyload);
            window.removeEventListener('resize', lazyload);
            window.removeEventListener('orientationChange', lazyload);
          }
        }, 20);
      };

      document.addEventListener('scroll', lazyload);
      window.addEventListener('resize', lazyload);
      window.addEventListener('orientationChange', lazyload);
    }
  },

  setReady(cookiePolicy, mediaInitialised) {
    this.log.enabled && console.log(`${this.log.prep} setReady | pageId ${this.pageId} | device ${this.device.type} | mediaInitialised ${mediaInitialised} | cookiePolicy`, this.log.clr, cookiePolicy);
    this.cookiePolicy = cookiePolicy;
    this.ready = true;
    this.mediaInitialised = mediaInitialised;
    if (mediaInitialised) {
      this.onReady();
    } else {
      this.show();
    }
  },

  show() {
    this.log.enabled && console.log(`${this.log.prep} show`, this.log.clr);
    this.cleansePlatformContent(this.pageId);
    $(document).trigger('boom_init');
    /**
     * Dispatch native boom_init event :: used to phase out the jquery trigger that
     * requires jQuery to be loaded with defer attribute as php views responding to this
     * trigger with $(document).on('boom_init',function () {xxx});
     * assume jQuery is loaded
     */
    const event = document.createEvent('Event');
    event.initEvent('boom_init_native', true, true);
    document.dispatchEvent(event);
    $('.loading_overlay').hide();

    if (this.device) {
      // Trigger scrollTo top functionality
      this.scrollToTopSetup(this.device);
    }

    $.each($('.web-game-link-device-smartphone, [web-game-link-device-smartphone]'), function () {
      if ($(this).attr('smartphone_same_window') == 1) {
        $(this).attr({
          target: '_self',
        });
      } else {
        $(this).attr({
          target: '_blank',
        });
      }
    });

    $.each($('.web-game-link-device-tablet, [web-game-link-device-tablet]'), function () {
      if ($(this).attr('tablet_header') == 0) {
        $(this).attr({
          target: '_blank',
        });
      }
    });

    this.setUpCarousels();
    const carouselIsSticky = this.pageId !== 'gameplay' && this.pageId !== 'footerlink';
    if (!this.carouselState.shows) {
      this.carouselState.shows = true;
      new ShowsCarouselLazy(carouselIsSticky);
    }
  },

  onReady: function () {
    if (!this.device || !this.ready || !this.mediaInitialised) {
      return;
    }

    this.log.enabled && console.log(`${this.log.prep} onReady`, this.log.clr);

    this.show();

    if ($('.like_button[data-id]').length > 0) {
      // Trigger emojis
      const targets = { emojiContainerId: 'emojis__container', emojiTargetClass: 'emoji__target', contentTargetId: 'mobile-emojis' };
      new Emojis({ cookiePolicy: this.cookiePolicy, targets });
    }

    if ($('#letscreate-feed_template').length > 0) {
      /**
       * CMS is setting this to display:none;
       * https://staging-boomerangtv-uk.turner-apps.com/admin/cms/edit/tracking
       */
      this.letscreateFeedBlock = new FeedBlock({ type: FeedBlock.LETSCREATE, selector: '.letscreate-feed', cookiePolicy: this.cookiePolicy });
      this.letscreateFeedBlock.init({ template: document.querySelector('#letscreate-feed_template') });
    }

    if ($('#animals-feed_template').length > 0) {
      this.animalsFeedBlock = new FeedBlock({ type: FeedBlock.ANIMALS, selector: '.animals-feed', cookiePolicy: this.cookiePolicy });
      this.animalsFeedBlock.init({ template: document.querySelector('#animals-feed_template') });
    }
  },

  cleansePlatformContent: function (pageId) {
    if ('apps'.indexOf(pageId) !== -1 || 'appplay'.indexOf(pageId) !== -1) {
      return;
    }

    switch (TurnerToons.device) {
      case 'desktop':
        $('.show_for_tablet').remove();
        $('.show_for_smartphone').remove();
        break;
      case 'tablet':
        $('.show_for_desktop').remove();
        $('.show_for_smartphone').remove();
        break;
      case 'smartphone':
        $('.show_for_desktop').remove();
        $('.show_for_tablet').remove();
        break;
    }

    const itemsToCleanse = $('.carousel_item, .featured_header_item, .featured_item');
    this.log.enabled && console.log(`${this.log.prep} cleansePlatformContent : items = ${itemsToCleanse.length}`, this.log.clr);
    itemsToCleanse.each(function () {
      const $this = $(this);
      if ($this.hasClass('featured_header_item--app')) {
        return;
      }

      if (TurnerToons.user_platform != 'other' && !$this.hasClass('platform_all')) {
        if (!$this.hasClass('platform_' + TurnerToons.user_platform)) {
          $this.remove();
        }
      }

      if ($this.attr('desktop_game')) {
        if (TurnerToons.device == 'desktop' && $this.attr('desktop_game') == '1') {
          $this.find('.web-game-link-device-tablet, [web-game-link-device-tablet]').remove();
          $this.find('.web-game-link-device-smartphone, [web-game-link-device-smartphone]').remove();
        } else if (TurnerToons.device == 'tablet' && $this.attr('tablet_game') == '1') {
          $this.find('.web-game-link-device-desktop, [web-game-link-device-desktop]').remove();
          $this.find('.web-game-link-device-smartphone, [web-game-link-device-smartphone]').remove();
        } else if (TurnerToons.device == 'smartphone' && $this.attr('smartphone_game') == '1') {
          $this.find('.web-game-link-device-desktop, [web-game-link-device-desktop]').remove();
          $this.find('.web-game-link-device-tablet, [web-game-link-device-tablet]').remove();
        } else {
          $this.remove();
        }
      }
    });
  },

  scrollToTopSetup: function (device) {
    // scroll to top functionality
    // we don't want this on Mobile/Tablet when body has 'gameplay_page--mobile-container' class
    if ($('body').hasClass('gameplay_page--mobile-container') && device.type === 'desktop') {
      window.addEventListener('scroll', function () {
        const y = window.scrollY;
        document.getElementById('scroll-top-anchor').style.display = y < 500 ? 'none' : 'flex';
      });
    } else if (!$('body').hasClass('gameplay_page--mobile-container')) {
      window.addEventListener('scroll', function () {
        const y = window.scrollY;
        document.getElementById('scroll-top-anchor').style.display = y < 500 ? 'none' : 'flex';
      });
    }
  },

  setUpCarousels: function () {
    if (!this.carouselState.slider) {
      this.carouselState.slider = true;
      new SliderCarouselLazy(this.pageId);
    }

    if (!this.carouselState.app) {
      this.carouselState.app = true;
      new AppScreenshotsCarousel(this.pageId);
    }

    /**
     * Once we have removed the legacy carousel toggle and so default to using the new carousel in the gallery module
     * site/config.php : slider_content_block_legacy_carousel_active
     *
     * We can remove the import
     * import { CarouselWidget } from './modules/CarouselWidget.js';
     * and below code
     */
    const legacyCarouselActive = TurnerToons?.legacyCarouselActive ?? false;
    if (legacyCarouselActive && !this.carouselState.small) {
      this.carouselState.small = true;
      this.smallCarouselSetup();
    }
  },

  smallCarouselSetup: function () {
    const t = this; // ughhhhh
    $('.block-list:not(.simpleblocks)').each(function (index, obj) {
      const content = $(obj).find('.content_item').length;
      if (content > 0) {
        $(obj).show();
      } else {
        $(obj).remove();
      }
    });

    this.carouselthree_array = [];
    // init carousel widgets
    $('.carousel_widget.three_onscreen:not(.simpleblocks)').each((index, item) => {
      console.log(`${this.log.prep} smallCarouselSetup : item`, this.log.clr, $(item));
      t.carouselthree_array.push(
        new CarouselWidget({
          type: 'SMALL',
          $carousel: $(item),
          wrapper_classname: '.carousel_wrapper',
          children_classname: '.carousel_item',
          area_classname: '.carousel_area',
          auto_scroll: false,
          swipe_enabled: true,
          swipe_hint_enabled: false,
          auto_scroll_if_no_touch: false,
          child_width_percentage: 48.333,
          child_margin_percentage: 3,
          onscreen_count: 3,
          no_transforms: false,
          resize_callback: carousel_threeup_callback,
          touchstart_callback: threeup_touchstart_callback,
          touchend_callback: threeup_touchend_callback,
        })
      );
    });

    for (let i = 0; i < t.carouselthree_array.length; i++) {
      // this.log.enabled && console.log(`${this.log.prep} carouselthree_array`, this.log.clr, this.carouselthree_array[i]);
      this.carouselthree_array[i].init();
    }

    function carousel_threeup_callback() {
      if (window.Modernizr.mq('only all and (max-width: 767px)')) {
        for (let i = 0; i < t.carouselthree_array.length; i++) {
          t.carouselthree_array[i].child_width_percentage = 100;
          t.carouselthree_array[i].child_margin_percentage = 0;

          t.carouselthree_array[i].interaction_interupt = true;
          t.carouselthree_array[i].swipe_hint_enabled = false;
          t.carouselthree_array[i].swipe_enabled = false;

          t.carouselthree_array[i].remove_touch();
        }
      } else {
        for (let i = 0; i < t.carouselthree_array.length; i++) {
          t.carouselthree_array[i].child_width_percentage = 31.333;
          t.carouselthree_array[i].child_margin_percentage = 3;

          t.carouselthree_array[i].interaction_interupt = false;
          t.carouselthree_array[i].swipe_hint_enabled = false;
          t.carouselthree_array[i].swipe_enabled = true;

          t.carouselthree_array[i].setup_touch();
        }
      }
    }

    function threeup_touchstart_callback(t) {
      t.$carouselContainer.find('.nav_button').addClass('show');
    }

    function threeup_touchend_callback(t) {
      setTimeout(function () {
        t.$carouselContainer.find('.nav_button').removeClass('show');
      }, 2000);
    }
  },
};

export { App };
