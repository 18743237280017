import storage from 'simplestorage.js';
import FeedBlockAnimals from './FeedBlockAnimals';
import FeedBlockLetscreate from './FeedBlockLetscreate';
import { getLogOptions } from 'constants/logging';

class FeedBlock {
  constructor({ type, selector, cookiePolicy }) {
    this.log = getLogOptions({ id: 'feedblock' });

    this.type = type;
    this.selector = selector;
    this.cookiePolicy = cookiePolicy;
    this.blocks = [];
    /**
     * * Animals Blocks
     * featured_items
     * entries
     * winners
     *
     * * LetsCreate Blocks
     * featured_items
     * entries
     * winners : if NO_ACTIVE_WINNERS populates winners with Trending (get_approved_entry_details)
     */
    this.handleLikeClick = this.handleLikeClick.bind(this);
    this.onCheckBreakPoint = this.checkBreakPoint.bind(this);
    this.breakpoint = window.matchMedia('(min-width:31.25em)');
    if (!TurnerToons.isIE) {
      this.breakpoint.addEventListener('change', this.onCheckBreakPoint);
    } else {
      this.breakpoint.addListener('change', this.onCheckBreakPoint);
    }
  }

  checkBreakPoint() {
    if (this.breakpoint.matches === true) {
      this.blocks.forEach((block) => {
        block && block.handleResize('horizontal');
      });
      // else if a small viewport and single column layout needed
    } else if (this.breakpoint.matches === false) {
      this.blocks.forEach((block) => {
        block && block.handleResize('vertical');
      });
    }
  }

  init({ template }) {
    if (!$(this.selector).length) {
      return;
    }
    const url_fragment = this.type === FeedBlock.ANIMALS ? '#/pet/' : '#/creation/';
    this.log.enabled && console.log(`${this.log.prep} FeedBlock.init | type : ${this.type}`, this.log.clr);
    $(this.selector).each((index, item) => {
      const feedItemName = item.getAttribute('feeditemname');
      const feedItemType = item.getAttribute('feedItemType');
      // const feedLimit = TurnerToons.device === 'smartphone' ? 3 : parseInt(item.getAttribute('limit')) || 3;
      const feedLimit = parseInt(item.getAttribute('limit')) || 3;
      const options = {
        wrapper: $(item).find('.wrapper'),
        el: $(item).find('.carousel_wrapper'),
        apiURL: item.getAttribute('api'),
        apiAction: item.getAttribute('api-action'),
        feedItemName: feedItemName,
        feedItemType: feedItemType,
        itemLinkBaseURL: item.getAttribute('linkto'),
        url_fragment: url_fragment,
        itemTemplate: template,
        limit: feedLimit,
        target: item.getAttribute('target'),
        log: this.log,
      };
      if (this.type === FeedBlock.LETSCREATE) {
        // Lets create Specific
        options.altTitle = item.getAttribute('altTitle');
        options.titleDomEl = $(item).find('#winnersTitle') || null;
      }
      const block = this.type === FeedBlock.ANIMALS ? new FeedBlockAnimals(options) : new FeedBlockLetscreate(options);
      this.blocks.push(block);
    });
    this.requestCount = this.blocks.length;
    this.blocks.forEach((block) => {
      const request = block.getFeedData();
      request
        .done((res) => {
          this.getDataComplete();
        })
        .fail((e) => {
          this.getDataComplete();
        });
    });
  }

  getDataComplete() {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.blocks.forEach((block) => {
        const result = block.renderFeedData();
        if (result && result.el) {
          this.setupLikes(result);
        } else {
          $(block.el[0].closest('.block-list.simpleblocks')).remove();
        }
      });

      if (this.cookiePolicy.performanceCookiesEnabled === true) {
        if (document.getElementsByClassName('like_button--hidden').length > 0) {
          document.getElementsByClassName('like_button--hidden').forEach((el) => {
            el.classList.remove('like_button--hidden');
          });
        }
      }
    }
  }

  // LIKES
  getLikedCookie(like_cookie_id) {
    let cookie = {};
    if (storage.canUse()) {
      cookie = storage.get(like_cookie_id) || {};
    }
    return cookie;
  }

  setLikedCookie(like_cookie_id, id) {
    let cookie = {};
    if (storage.canUse() && this.cookiePolicy.performanceCookiesEnabled === true) {
      cookie = storage.get(like_cookie_id);
      let newcookie = Object.assign({}, cookie);
      newcookie[id] = 1;
      storage.set(like_cookie_id, newcookie);
    }
  }

  setupLikes({ el, like_api_url, ratings_id, like_cookie_id }) {
    this.log.enabled && console.log(`${this.log.prep} FeedBlock.setupLikes | performanceCookiesEnabled ${this.cookiePolicy.performanceCookiesEnabled}`, this.log.clr);
    let cookieData = this.getLikedCookie(like_cookie_id);
    const like_buttons = el.find('.like_button--feeds');
    like_buttons.each((index, likeButtnEl) => {
      if (this.cookiePolicy.performanceCookiesEnabled !== true) {
        $(likeButtnEl).addClass('like_button--hidden');
      }
      const external_id = likeButtnEl.getAttribute('data-externalid');
      const btn = $(likeButtnEl);
      if (cookieData && cookieData[external_id] === 1) {
        btn.addClass('liked');
      } else {
        btn.on('click', { target: btn, external_id, like_api_url, ratings_id, like_cookie_id }, this.handleLikeClick);
      }
    });
  }

  handleLikeClick(event) {
    const { target, external_id, like_api_url, ratings_id, like_cookie_id } = event.data;
    if (target.attr('ajaxing') != 'true') {
      target.attr('ajaxing', 'true');
      $.ajax({
        url: like_api_url,
        method: 'POST',
        dataType: 'json',
        data: {
          external_id: external_id,
          rating_id: ratings_id,
          _xss_cookie: null,
        },
      })
        .done((response) => {
          target.attr('ajaxing', 'false');
          target.off('click', this.handleLikeClick);
          if (response.result.toUpperCase() === 'SUCCESS') {
            /*
             *  Note - api just returns SUCCESS so using dom to add likes
             *  However, if the like result is K formatted (eg 1201 = 1.2K)
             *  then do nothing visually
             */
            const domCount = target.find('.count').text().toLowerCase();
            if (domCount.indexOf('k') === -1) {
              // is not K formatted so inc the count
              target.find('.count').text(parseInt(domCount) + 1);
            }
            this.setLikedCookie(like_cookie_id, external_id);
            target.addClass('liked');
          } else if (response.reason && response.reason == 'ALREADY_VOTED') {
            this.log.enabled && console.log(`${this.log.prep} FeedBlock ALREADY_VOTED`);
            this.setLikedCookie(like_cookie_id, external_id);
            target.addClass('liked');
          } else {
            console.error('FeedBlock', response.result, response.reason, like_api_url);
          }
        })
        .fail((e) => {
          console.error('FeedBlock', e);
        });
    }
  }
}

FeedBlock.ANIMALS = 'ANIMALS';
FeedBlock.LETSCREATE = 'LETSCREATE';
export default FeedBlock;
