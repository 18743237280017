/**
 * Emojis Class.
 * Entry file for the emojis functionality
 */

import * as API from 'services/api';
import storage from 'simplestorage.js';

import { getLogOptions } from 'constants/logging';

const STORAGE_KEY = 'site_emoji_likes';
const GIF_TIMER = 1800;

export default class Emojis {
  constructor({ cookiePolicy, targets }) {
    this.log = getLogOptions({ id: 'emojis' });
    const { emojiContainerId = 'emojis__container', emojiTargetClass = 'emoji__target', contentTargetId = 'mobile-emojis' } = targets;
    this.emojiContainer = document.getElementById(emojiContainerId);
    this.emojis = document.getElementsByClassName(emojiTargetClass);
    this.fetching = false;
    this.storedLikes = storage.get(STORAGE_KEY) || {};
    this.cookiePolicy = cookiePolicy;

    // Get content ID & Type
    const contentTarget = document.getElementById(contentTargetId);
    this.contentId = contentTarget ? contentTarget.dataset.id : null;
    this.contentType = contentTarget ? contentTarget.dataset.type : null;

    if (!this.contentId && !this.contentType) {
      this.log.enabled && console.log(`${this.log.prep} Emojis bailed because of missing contentId or contentType`, this.log.warn);
      return;
    }
    this.log.enabled && console.log(`${this.log.prep} Emojis created`, this.log.clr);
    // bindings
    this.clickedEntryCategory = this.clickedEntryCategory.bind(this);
    this.showAnimatedGif = this.showAnimatedGif.bind(this);
    this.likeEntry = this.likeEntry.bind(this);
    this.unlikeEntry = this.unlikeEntry.bind(this);

    this.init();
    this.getRatings();
  }

  init() {
    if (!this.storedLikes[this.contentType]) {
      this.storedLikes[this.contentType] = {};
      this.storedLikes[this.contentType][this.contentId] = {};
      if (this.cookiePolicy.performanceCookiesEnabled) {
        storage.set(STORAGE_KEY, this.storedLikes);
      }
    } else if (!this.storedLikes[this.contentType][this.contentId]) {
      this.storedLikes[this.contentType][this.contentId] = {};
      if (this.cookiePolicy.performanceCookiesEnabled) {
        storage.set(STORAGE_KEY, this.storedLikes);
      }
    }

    /*
     * IOS 9 NOTE : switched below because was breaking on ios 9
     */
    for (const emoji of this.emojis) {
      emoji.addEventListener('click', () => {
        this.clickedEntryCategory({ id: emoji.dataset.emojiId, item: emoji });
      });
    }
    // for (const [key, value] of Object.entries(this.emojis)) {
    //   value.addEventListener('click', () => {
    //     this.clickedEntryCategory({ id: value.dataset.emojiId, item: value });
    //   });
    // }
  }

  showAnimatedGif(item) {
    const gif = item.dataset.gif;
    const png = item.dataset.png;

    item.querySelectorAll('img')[0].src = gif;
    setTimeout(() => {
      item.querySelectorAll('img')[0].src = png;
    }, GIF_TIMER);
  }

  setHasLiked({ id, uuid }) {
    this.storedLikes[this.contentType][this.contentId][id] = uuid;
    if (this.cookiePolicy.performanceCookiesEnabled) {
      storage.set(STORAGE_KEY, this.storedLikes);
    }
  }

  removeHasLiked({ id, uuid }) {
    delete this.storedLikes[this.contentType][this.contentId][id];
    if (this.cookiePolicy.performanceCookiesEnabled) {
      storage.set(STORAGE_KEY, this.storedLikes);
    }
  }

  // Check if user has already liked
  clickedEntryCategory({ id, item }) {
    console.log(this.storedLikes[this.contentType][this.contentId]);
    if (!this.storedLikes[this.contentType][this.contentId][id]) {
      this.likeEntry(id);
      this.showAnimatedGif(item);
    } else {
      const uuid = this.storedLikes[this.contentType][this.contentId][id];
      this.unlikeEntry(id, uuid);
    }
  }

  likeEntry(emojiID) {
    API.trackLikeService(this.contentType, this.contentId, emojiID).then((response) => {
      if (response.result.success) {
        const { uuid } = response.result;
        this.setHasLiked({ id: emojiID, uuid });
        this.getRatings();
      } else {
        console.error('like', response.reason);
      }
    });
  }

  unlikeEntry(emojiID, uuid) {
    API.trackUnlikeService(this.contentType, this.contentId, emojiID, uuid).then((response) => {
      if (response.result.success) {
        this.removeHasLiked({ id: emojiID, uuid });
        this.getRatings();
      } else {
        console.error('like', response.reason);
      }
    });
  }

  getRatings() {
    API.getRatingsService(this.contentType, this.contentId).then((response) => {
      if (response) {
        this.renderLikes(response);
      } else {
        console.error('no likes');
      }
    });
  }

  renderLikes(response) {
    let totalCount = 0;
    if (Object.entries(response).length > 0) {
      for (const [key, value] of Object.entries(response)) {
        if (document.querySelectorAll(`[data-emoji-id="${key}"]`)) {
          const emoji = document.querySelectorAll(`[data-emoji-id="${key}"]`);
          totalCount = totalCount + parseInt(value);

          console.log('values', { key, value });
          console.log('emoji', emoji);

          if (key === 'LIKE') {
            if (emoji[0] && emoji[0].querySelectorAll('.count') && emoji[1] && emoji[1].querySelectorAll('.count')) {
              emoji[0].querySelectorAll('.count')[0].innerHTML = this._format(value);
              emoji[1].querySelectorAll('.count')[0].innerHTML = this._format(value);
            }
          } else {
            if (emoji[0] && emoji[0].querySelectorAll('.count')) {
              emoji[0].querySelectorAll('.count')[0].innerHTML = this._format(value);
            }
          }
        }
      }
    }

    document.querySelectorAll(`.emoji--total`)[0].querySelectorAll('.count')[0].innerHTML = this._format(totalCount);
  }

  _format(num) {
    const si = [
      { value: 1e18, symbol: 'E' },
      { value: 1e15, symbol: 'P' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    num = parseFloat(num);
    const digits = num > 100000 && num < 1000000 ? 0 : 1;
    for (i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      }
    }
    return (num + 0).toFixed(digits).replace(rx, '$1');
  }
}
